import React from 'react';
import {
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Heading,
  IconButton,
  SimpleGrid,
  Stack,
  Image,
  Text,
} from '@chakra-ui/react';
import { Gift, gifts } from '../../gifts';
import PlayIcon from '../../components/icons/PlayIcon';
import WebsiteIcon from '../../components/icons/WebsiteIcon';

type Props = {
  readonly gift: Gift;
};

function GiftCatalogItem({ gift }: Props) {
  const { id, name, imageUri, description, websiteUri, youtubeUri } = gift;
  return (
    <Card id={id} direction="column" overflow="hidden" variant="outline">
      <Image objectFit="cover" maxW="100%" src={imageUri} alt={name} />

      <Stack>
        <CardBody>
          <Heading size="md">{name}</Heading>
          <Text py="2">{description}</Text>
        </CardBody>

        <CardFooter>
          <ButtonGroup>
            <IconButton
              aria-label="Website"
              icon={<WebsiteIcon />}
              variant="outline"
              colorScheme="yellow"
              as="a"
              href={websiteUri}
              target="_blank"
            />
            <IconButton
              aria-label="Play Video"
              icon={<PlayIcon />}
              variant="outline"
              colorScheme="red"
              hidden={!youtubeUri}
              as="a"
              href={youtubeUri}
              target="_blank"
            />
          </ButtonGroup>
        </CardFooter>
      </Stack>
    </Card>
  );
}

function GiftCatalog() {
  return (
    <SimpleGrid spacing={4} columns={{ base: 1, lg: 2, xl: 3 }}>
      {gifts.map((gift) => (
        <GiftCatalogItem key={gift.id} gift={gift} />
      ))}
    </SimpleGrid>
  );
}

export default GiftCatalog;
