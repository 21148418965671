import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import React from 'react';

type Props = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly header: string;
  readonly body: string;
  readonly buttonText?: string;
};

function ErrorDialog({ isOpen, onClose, header, body, buttonText }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            {buttonText || 'OK'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ErrorDialog;
