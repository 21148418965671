import React from 'react';
import { Stack, Text, Heading, Button } from '@chakra-ui/react';

function Error({
  message,
  description,
}: {
  message: string;
  description: string;
}) {
  const resetApp = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Stack>
      <Heading as="h2" size="xl">
        Error
      </Heading>
      <Text mt={4} color="red.500">
        {message}
      </Text>
      <Text mt={4}>Description: {description}</Text>
      <Text mt={4}>
        <Button onClick={resetApp}>Reset App</Button>
      </Text>
    </Stack>
  );
}

export default Error;
