import React from 'react';

export interface Credentials {
  readonly username: string;
  readonly password: string;
}

export const CredentialsContext = React.createContext<Credentials>({
  username: 'dummy',
  password: 'dummy',
});
