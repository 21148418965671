import frozenImage from './assets/images/frozen.jpg';
import wizardOfOzImage from './assets/images/the-wizard-of-oz.jpeg';
import lionKingImage from './assets/images/the-lion-king.jpeg';
import mrsDoubtfireImage from './assets/images/mrs-doubtfire.webp';
import backToTheFutureImage from './assets/images/back-to-the-future.jpeg';
import mammaMiaImage from './assets/images/mamma-mia.jpeg';
import twistMuseumImage from './assets/images/twist-museum.jpeg';
import framelessImage from './assets/images/frameless.webp';
import curseOfDavyJonesImage from './assets/images/the-curse-of-davy-jones.webp';
import seekersOfTheShardImage from './assets/images/seekers-of-the-shard.webp';
import starTrekImage from './assets/images/star-trek.webp';
import amberSkyImage from './assets/images/amber-sky.webp';

export interface Gift {
  readonly id: string;
  readonly name: string;
  readonly imageUri: string;
  readonly description: string;
  readonly websiteUri: string;
  readonly youtubeUri?: string;
}

export const gifts: Gift[] = [
  {
    id: 'frozen',
    name: 'Frozen',
    imageUri: frozenImage,
    description: 'Frozen the musical in London',
    websiteUri: 'https://frozenthemusical.co.uk/',
    youtubeUri: 'https://youtu.be/IYryppT1oP4',
  },
  {
    id: 'wizard-of-oz',
    name: 'The Wizard of Oz',
    imageUri: wizardOfOzImage,
    description:
      'The Wizard of Oz the musical in Southend (Cliffs Pavilion Tue 20 - Sun 25 Feb 2024)',
    websiteUri: 'https://uktour.wizardofozmusical.com/',
    youtubeUri: 'https://youtu.be/bPN6NqpwfEA',
  },
  {
    id: 'lion-king',
    name: 'The Lion King',
    imageUri: lionKingImage,
    description: 'The Lion King the musical in London',
    websiteUri: 'https://thelionking.co.uk/',
    youtubeUri: 'https://youtu.be/o_VPnwAsQDg',
  },
  {
    id: 'mrs-doubtfire',
    name: 'Mrs Doubtfire',
    imageUri: mrsDoubtfireImage,
    description: 'Mrs Doubtfire the musical in London',
    websiteUri: 'https://www.mrsdoubtfiremusical.co.uk/',
    youtubeUri: 'https://youtu.be/0F_73uqaKyw',
  },
  {
    id: 'back-to-the-future',
    name: 'Back to the Future',
    imageUri: backToTheFutureImage,
    description: 'Back to the Future the musical in London',
    websiteUri: 'https://www.backtothefuturemusical.com/london/',
    youtubeUri: 'https://youtu.be/rwU_HLUKv98',
  },
  {
    id: 'mamma-mia',
    name: 'Mamma Mia',
    imageUri: mammaMiaImage,
    description: 'Mamma Mia the musical in London',
    websiteUri: 'https://mamma-mia.com/london.php',
    youtubeUri: 'https://youtu.be/TPv6sAq54_U',
  },
  {
    id: 'twist-museum',
    name: 'Twist Museum',
    imageUri: twistMuseumImage,
    description: 'Museum of illusions in London',
    websiteUri: 'https://twistmuseum.com/',
  },
  {
    id: 'frameless',
    name: 'Frameless',
    imageUri: framelessImage,
    description: 'Immersive art gallery in London',
    websiteUri: 'https://frameless.com/',
  },
  {
    id: 'vr-curse-of-davy-jones',
    name: 'Curse of Davy Jones',
    imageUri: curseOfDavyJonesImage,
    description: 'Pirate themed virtual reality experience in London',
    websiteUri: 'https://sandboxvr.com/london/experience/davyjones',
    youtubeUri: 'https://youtu.be/LfrQvkAaUOI',
  },
  {
    id: 'vr-seekers-of-the-shard',
    name: 'Seekers of the Shard: Dragonfire',
    imageUri: seekersOfTheShardImage,
    description: 'Fantasy themed virtual reality experience in London',
    websiteUri: 'https://sandboxvr.com/london/experience/dragonfire',
    youtubeUri: 'https://youtu.be/hWKrSFKkkzQ',
  },
  {
    id: 'vr-star-trek',
    name: 'Star Trek: Discovery Away Mission',
    imageUri: starTrekImage,
    description: 'Star Trek themed virtual reality experience in London',
    websiteUri: 'https://sandboxvr.com/london/experience/startrek',
    youtubeUri: 'https://youtu.be/s5E0t96T36k',
  },
  {
    id: 'vr-amber-sky',
    name: 'Amber Sky 2088',
    imageUri: amberSkyImage,
    description: 'Cyberpunk themed virtual reality experience in London',
    websiteUri: 'https://sandboxvr.com/london/experience/ambersky',
    youtubeUri: 'https://youtu.be/pQ9DOIrZiK0',
  },
];

export const getGift = (id: string): Gift | undefined =>
  gifts.find((gift) => gift.id === id);
