import React from 'react';

export interface UserState {
  readonly giftId?: string;
}

export interface User {
  readonly name: string;
  readonly username: string;
  readonly lastLogin?: string;
  readonly state?: UserState;
}

interface IUserContext {
  readonly user: User;
  readonly updateState: (update: Partial<UserState>) => void;
}

export const UserContext = React.createContext<IUserContext>({
  user: { name: 'dummy', username: 'dummy' },
  updateState: () => {},
});
