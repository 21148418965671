import React from 'react';
import {
  Variant,
  VariantContext,
  VariantType,
  getVariant,
} from '../../variants';
import Error from '../../pages/error/Error';
import App from '../../pages/app/App';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../theme';
import DefaultApp from '../../pages/app/DefaultApp';

const extractVariantType = (hostname: string): VariantType | undefined => {
  const match = hostname.match(
    /^([^.]+\.)*(?<variant>michelle|fletcher|piper|lucas)(\.[^.]+)*$/
  );
  return match?.groups?.variant as VariantType | undefined;
};

const determineVariant = (hostname: string): Variant | undefined => {
  const variantType = extractVariantType(hostname);
  return variantType ? getVariant(variantType) : undefined;
};

function Root() {
  const variant = determineVariant(window.location.hostname);

  return (
    <ChakraProvider theme={theme}>
      {variant ? (
        <VariantContext.Provider value={variant}>
          <App />
        </VariantContext.Provider>
      ) : (
        <DefaultApp />
      )}
    </ChakraProvider>
  );
}

export default Root;
