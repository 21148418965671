import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  SimpleGrid,
} from '@chakra-ui/react';
import { Gift, getGift, gifts } from '../../gifts';
import GiftCard from '../../components/gift/GiftCard';
import ConfirmModal from '../../components/modals/ConfirmModel';

type Props = {
  readonly selectedId?: string;
  readonly onSelect: (gift: Gift) => void;
};

function GiftSelection({ selectedId, onSelect }: Props) {
  const selectedGift = selectedId && getGift(selectedId);
  const [newGift, setNewGift] = React.useState<Gift | undefined>();

  const onSelectWithPrompt = (gift: Gift) => {
    if (selectedGift && selectedGift.id !== gift.id) {
      setNewGift(gift);
    } else {
      onSelect(gift);
    }
  };

  const onPromptConfirm = () => {
    if (newGift) {
      onSelect(newGift);
    }
    setNewGift(undefined);
  };

  const onPromptCancel = () => {
    setNewGift(undefined);
  };

  return (
    <>
      {selectedGift && (
        <Alert mb={4} status="success" variant="solid">
          <AlertIcon />
          <AlertDescription width="100%">
            You've chosen {selectedGift.name}!{' '}
          </AlertDescription>
          <Button
            variant="outline"
            colorScheme="black"
            size="sm"
            onClick={() => onSelect(selectedGift)}
          >
            Keep Gift
          </Button>
        </Alert>
      )}
      <SimpleGrid spacing={4} columns={{ base: 1, lg: 2, xl: 3 }}>
        {gifts.map((gift) => (
          <GiftCard
            key={gift.id}
            gift={gift}
            selected={selectedId === gift.id}
            onSelect={onSelectWithPrompt}
          />
        ))}
      </SimpleGrid>
      {selectedGift && newGift && (
        <ConfirmModal
          isOpen={!!newGift}
          onConfirm={onPromptConfirm}
          onCancel={onPromptCancel}
          header="Change Selection"
          body={`Are you sure you'd prefer ${newGift.name} over ${selectedGift.name}?`}
          confirmText="Change"
        />
      )}
    </>
  );
}

export default GiftSelection;
