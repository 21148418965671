import React from 'react';
import { User } from '../../user';
import {
  Button,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

type Props = {
  users: User[];
  reload: () => void;
};

function WaitingForUsers({ users, reload }: Props) {
  const names = users.map((u) => u.name).join(', ');
  return (
    <Stack>
      <Heading as="h3" size="md">
        Waiting for all users to login...
      </Heading>
      <Text fontSize="lg">The following users have yet to login:</Text>
      <UnorderedList>
        {users.map((u) => (
          <ListItem>{u.name}</ListItem>
        ))}
      </UnorderedList>
      <Text fontSize="lg">
        Try helping them, and click below once all have logged in.
      </Text>
      <Button variant="solid" colorScheme="green" onClick={reload}>
        Refresh
      </Button>
    </Stack>
  );
}

export default WaitingForUsers;
